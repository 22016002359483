var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "clearfix" },
    [
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|alpha_dash|min:3",
            expression: "'required|alpha_dash|min:3'",
          },
        ],
        staticClass: "w-full",
        attrs: {
          "data-vv-validate-on": "blur",
          "label-placeholder": "Name",
          name: "displayName",
          placeholder: "Name",
        },
        model: {
          value: _vm.displayName,
          callback: function ($$v) {
            _vm.displayName = $$v
          },
          expression: "displayName",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("displayName"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|email",
            expression: "'required|email'",
          },
        ],
        staticClass: "w-full mt-6",
        attrs: {
          "data-vv-validate-on": "blur",
          name: "email",
          type: "email",
          "label-placeholder": "Email",
          placeholder: "Email",
        },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("email"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|min:6|max:10",
            expression: "'required|min:6|max:10'",
          },
        ],
        ref: "password",
        staticClass: "w-full mt-6",
        attrs: {
          type: "password",
          "data-vv-validate-on": "blur",
          name: "password",
          "label-placeholder": "Password",
          placeholder: "Password",
        },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("password"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "min:6|max:10|confirmed:password",
            expression: "'min:6|max:10|confirmed:password'",
          },
        ],
        staticClass: "w-full mt-6",
        attrs: {
          type: "password",
          "data-vv-validate-on": "blur",
          "data-vv-as": "password",
          name: "confirm_password",
          "label-placeholder": "Confirm Password",
          placeholder: "Confirm Password",
        },
        model: {
          value: _vm.confirm_password,
          callback: function ($$v) {
            _vm.confirm_password = $$v
          },
          expression: "confirm_password",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("confirm_password"))),
      ]),
      _c(
        "vs-checkbox",
        {
          staticClass: "mt-6",
          model: {
            value: _vm.isTermsConditionAccepted,
            callback: function ($$v) {
              _vm.isTermsConditionAccepted = $$v
            },
            expression: "isTermsConditionAccepted",
          },
        },
        [_vm._v("I accept the terms & conditions.")]
      ),
      _c(
        "vs-button",
        { staticClass: "mt-6", attrs: { type: "border", to: "/login" } },
        [_vm._v("Login")]
      ),
      _c(
        "vs-button",
        {
          staticClass: "float-right mt-6",
          attrs: { disabled: !_vm.validateForm },
          on: { click: _vm.registerUserJWt },
        },
        [_vm._v("Register")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }